import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React, { useState } from 'react'

import Carousel from 'components/carousel/Carousel'
import PageHead from 'components/head/PageHead'
import Heading from 'components/heading/Heading'
import PageWrapper from 'components/layout/page-wrapper'
import DownloadLogoSetButton from 'components/pages/brand/DownloadLogoSet/Button'
import DownloadLogoSetLink from 'components/pages/brand/DownloadLogoSet/Link'
import Gallery from 'components/pages/brand/Gallery'
import DownloadSection from 'components/pages/shared/DownloadSection'
import BrandData from 'data/pages/Contents/brand.json'
import buildImageObject from 'js/utils/buildImgObject'
import { useRellax } from 'js/utils/hooks'

import 'static/brand/scss/styles.scss'

const images = {
  headerImage: {
    aspectRatio: 1.09,
    path: '/brand/img/',
    fileType: 'jpg',
    files: [
      {
        fileName: 'header-bg',
        width: 811,
      },
    ],
  },
}

const downloadLogoItemsDark = {
  label: 'Exodus Logo dark',
  files: [
    '/brand/dl/images/Exodus_logo_dark.png',
    '/brand/dl/images/Exodus_logo_dark.svg',
    '/brand/dl/images/Exodus_logo_dark.ai',
  ],
}

const downloadLogoItemsWhite = {
  label: 'Exodus Logo white',
  files: [
    '/brand/dl/images/Exodus_logo_white.png',
    '/brand/dl/images/Exodus_logo_white.svg',
    '/brand/dl/images/Exodus_logo_white.ai',
  ],
}

const downloadLogoItemsSymbol = {
  label: 'Exodus Symbol',
  files: [
    '/brand/dl/images/Exodus_symbol.png',
    '/brand/dl/images/Exodus_symbol.svg',
    '/brand/dl/images/Exodus_symbol.ai',
  ],
}

const HeaderBackground = () => {
  return (
    <div className="x-page-brand__header-background brand-rellax" data-rellax-speed="-4">
      <Img fluid={buildImageObject(images.headerImage)} durationFadeIn={2000} />
      <Img fluid={buildImageObject(images.headerImage)} durationFadeIn={2000} />

      <span className="x-page-brand__header-background__shader" />
    </div>
  )
}

const HeaderSection = () => {
  return (
    <header className="x-page-brand__header">
      <HeaderBackground />
      <img src="/brand/img/logo.svg" />
      <Heading page="brand" />
      <h2>{BrandData.header.description}</h2>
      <DownloadLogoSetButton
        copy={BrandData.header.cta.copy}
        items={[downloadLogoItemsDark, downloadLogoItemsWhite, downloadLogoItemsSymbol]}
        name={BrandData.header.cta.fileName}
      />
    </header>
  )
}

const SymbolismSection = () => {
  const [index, setIndex] = useState(0)

  return (
    <div className="x-page-brand__symbolism">
      <div className="x-page-brand__symbolism__intro">
        <span dangerouslySetInnerHTML={{ __html: BrandData.symbolism.intro }} />
      </div>

      <div className="x-page-brand__symbolism__image-container">
        <img src="/brand/img/logo-with-halo.png" />
      </div>

      <div className="x-page-brand__symbolism__gallery-container">
        <Gallery activeIndex={index} />
      </div>

      <div className="x-page-brand__symbolism__carousel-container">
        <Carousel
          items={BrandData.symbolism.carousel.map((item, index) => (
            <CarouselItem
              key={index}
              number={index + 1}
              total={BrandData.symbolism.carousel.length}
              {...item}
            />
          ))}
          disablePeriod={500}
          onChange={setIndex}
        />
      </div>
    </div>
  )
}

const GrowthSection = () => {
  return (
    <div className="x-page-brand__growth">
      <div className="x-page-brand__growth__box">
        <div className="x-page-brand__growth__box__background">
          <div />
        </div>

        <h2>{BrandData.growth.title}</h2>

        <p>{BrandData.growth.description}</p>

        <div className="x-page-brand__growth__founders">
          <div>
            <img src="/brand/img/JP-Richardson.png" />
            <p>{BrandData.growth.founders[0].name}</p>
            <p>{BrandData.growth.founders[0].title}</p>
          </div>

          <span>&</span>

          <div>
            <img src="/brand/img/Daniel-Castagnoli.png" />
            <p>{BrandData.growth.founders[1].name}</p>
            <p>{BrandData.growth.founders[1].title}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const ExplainerSection = () => {
  return (
    <div className="x-page-brand__explainer">
      <div>
        <img src="/brand/img/logo-with-measurements.png" />

        {BrandData.explainer.map((text, index) => (
          <p key={index}>{text}</p>
        ))}

        {BrandData.explainerDownloadMediaKit.copy && (
          <a href={BrandData.explainerDownloadMediaKit.path} target="_blank">
            {BrandData.explainerDownloadMediaKit.copy}
          </a>
        )}
      </div>

      <div>
        <img src="/brand/img/logo-colors.png" />
      </div>
    </div>
  )
}

const FilesSection = () => {
  return (
    <div className="x-page-brand__files">
      <div className="x-page-brand__files__card">
        <h2>{BrandData.files.title}</h2>
        <p>{BrandData.files.description}</p>
      </div>

      {BrandData.files.cta &&
        BrandData.files.cta.length > 0 &&
        BrandData.files.cta.map(({ copy, fileName, icon }, index) => (
          <div key={index} className="x-page-brand__files__card">
            <img src={icon} />
            <div className="x-page-brand__files__separator" />
            <DownloadLogoSetLink
              copy={copy}
              items={
                (index === 0 && [downloadLogoItemsDark, downloadLogoItemsWhite]) ||
                (index === 1 && [downloadLogoItemsSymbol])
              }
              name={fileName}
            />
          </div>
        ))}
    </div>
  )
}

const BrowserExtensionSection = () => {
  return (
    <div className="x-page-brand__browser-extension">
      <div>
        <h2>{BrandData.browserExtension.title}</h2>

        <p>{BrandData.browserExtension.description}</p>

        <Link
          to={BrandData.browserExtension.button.path}
          className="x-page-brand__button x-page-brand__button--alt"
        >
          <span>{BrandData.browserExtension.button.text}</span>
        </Link>
      </div>
      <div>
        <img src="/brand/img/browser-extension-promo.png" />
      </div>
    </div>
  )
}

const CarouselItem = ({ number, total, title, description }) => {
  return (
    <div key={number} className="x-page-brand__carousel-item">
      <p>
        {number}/{total}
      </p>
      <p>{title}</p>
      <p>{description}</p>
    </div>
  )
}

const Separator = () => {
  return <div className="x-page-brand__separator" />
}

function BrandPage({ location }) {
  useRellax({ className: 'brand-rellax' })

  return (
    <PageWrapper
      className="x-page-brand"
      headerProps={{ location, trackerPage: 'brand' }}
      footerProps={{ enableSubscribeNotice: false, noBorder: true }}
    >
      <HeaderSection />
      <SymbolismSection />
      <Separator />
      <GrowthSection />
      <ExplainerSection />
      <Separator />
      <FilesSection />
      <BrowserExtensionSection />
      <DownloadSection page="brand" copy="Download exodus wallet" path="/download" />
    </PageWrapper>
  )
}

export default BrandPage

// <head> component:
export function Head() {
  return <PageHead page="brand" />
}
